import React, { useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Highlight from 'react-highlight'

import Layout from '../components/layout'
// import { wrapYoutubeVideo, highlight } from '../scripts/helpers'
import AuthorIndicator from '../components/authorindicator'
import TagList from '../components/taglist'

interface IPostProps {
    data: {
        ghostPost: IGhostPost
    },
    location: string
}

export default function Post({ data, location }: IPostProps) {
    const post = data.ghostPost
    const html = post.html;

    return (
        <Layout>
            <Helmet
                title={data.ghostPost.title}
                meta={[
                    {
                        name: 'title',
                        content: data.ghostPost.meta_title
                    },
                    {
                        name: 'description',
                        content: data.ghostPost.meta_description
                    },
                    {
                        name: 'twitter:title',
                        content: data.ghostPost.meta_title
                    },
                    {
                        name: 'twitter:description',
                        content: data.ghostPost.meta_description
                    },
                    {
                        name: 'og:title',
                        content: data.ghostPost.meta_title
                    },
                    {
                        name: 'og:description',
                        content: data.ghostPost.meta_description
                    },
                ]}
            />
            <TagList tags={post.tags} />
            <article className='font-serif prose prose-lg prose-slate dark:prose-invert prose-figure:w-full prose-headings:font-sans prose-code:text-xs'>
                <h1 className='font-bold dark:text-slate-300'>{post.title}</h1>
                <AuthorIndicator author={post.primary_author} datePosted={post.published_at} />

                <section className='bg-white dark:bg-slate-900 dark:border dark:border-slate-700 px-8 py-4 rounded-xl'>
                    <Highlight innerHTML={true}>
                        {html}
                    </Highlight>
                </section>
            </article>
        </Layout>
    )
}

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: {eq: $slug}) {
                id
                published_at(formatString: "MMMM DD, YYYY")
                title
                html
                meta_title
                meta_description
                primary_author {
                    name
                    slug
                }
                tags {
                    slug
                    name
                    visibility
                }
            }
    }
`